<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
    :max-width="width"
    class="zass"
    scrollable
  >
    <v-card :max-width="width" class="ma-auto">
      <v-skeleton-loader type="card">
        <v-toolbar color="secondary" dark dense>
          <print_button
            :custom_id="customId"
            :html_element="el || element"
          ></print_button>
          <export_to_pdf_btn
            :name="export_name"
            :custom_id="customId"
            :html_element="el || element"
          />
          <v-toolbar-title
            class="white--text subheading font-weight-bold"
            v-text="name"
          />
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('input', false)">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text :id="customId" ref="exported">
          <slot />
        </v-card-text>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "specific_card",
  components: {
    print_button: () => import("@/components/print_button"),
    export_to_pdf_btn: () => import("@/components/export_to_pdf_btn")
  },
  data: () => ({
    customId: null
  }),
  beforeMount() {
    this.customId =
      "asd5as1d3asd13as1d3asd13a2" + this.getRandomColor().replace(" ", "_");
  },
  computed: {
    el() {
      return document.getElementById(this.customId);
    },
    element() {
      let el = this.$refs.exported;
      return el ? el : document.getElementById(this.customId);
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    export_name: {
      type: String,
      default: ""
    },
    width: {
      type: [String, Number],
      default: 400
    }
  }
};
</script>

<style scoped lang="scss">
.zass {
  z-index: 9897979876987432 !important;
}
</style>
